import { Component, OnInit, OnDestroy } from '@angular/core';
import { RoomService } from 'src/app/services/room/room.service';
import { Subscription } from 'rxjs';
import { Room } from 'src/app/interfaces/room.interface';
import { Router } from '@angular/router';
import { PlayerService } from 'src/app/services/player/player.service';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit, OnDestroy {
  public room: Room;
  public roomSub: Subscription;

  constructor(
    private router: Router,
    private roomService: RoomService,
    private playerService: PlayerService
  ) { }

  ngOnInit(): void {
    if (this.roomService.room$) {
      this.roomSub = this.roomService.room$.subscribe((nextRoom) => {
        this.room = nextRoom;
        this.playerService.syncWithRoom(this.room);
      });
    } else {
      this.router.navigate(['/new']);
    }
  }

  remove() {

  }

  ngOnDestroy() {
    if (this.roomSub) {
      this.roomSub.unsubscribe();
    }
  }
}
