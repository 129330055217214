import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips'; 
import { MatSidenavModule } from '@angular/material/sidenav';
import { BoardComponent } from './components/board/board.component';
import { CardComponent } from './components/card/card.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { RoomComponent } from './components/room/room.component';
import { PrintColorPipe } from '../pipes/printColor.pipe';
import { TeamListComponent } from './components/team-list/team-list.component';
import { TeamBarComponent } from './components/team-bar/team-bar.component';
import { ControlsComponent } from './components/controls/controls.component';
import { FirstNamePipe } from '../pipes/firstName.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard'; 
import { MaxCharsPipe } from '../pipes/maxChars.pipe';
import { CountdownModule } from 'ngx-countdown';
import { ScoreboardComponent } from './components/scoreboard/scoreboard.component';
import { TimerComponent } from './components/timer/timer.component';

@NgModule({
  declarations: [
    BoardComponent,
    CardComponent,
    RoomComponent,
    PrintColorPipe,
    FirstNamePipe,
    MaxCharsPipe,
    TeamListComponent,
    TeamBarComponent,
    ControlsComponent,
    ScoreboardComponent,
    TimerComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    MatSidenavModule,
    MatChipsModule,
    ClipboardModule,
    CountdownModule
  ],
  exports: [
    BoardComponent,
    CardComponent,
    RoomComponent
  ]
})
export class GameModule { }
