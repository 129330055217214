import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Room } from '../interfaces/room.interface';
import { map, first } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JoinroomGuardService implements CanActivate{

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const roomId = route.params['roomId'];
      const pwd = route.params['password'];
      
      const doc = this.afs.doc<Room>(`rooms/${roomId}`);
      const roomData = await doc.get().pipe(first()).toPromise();
      if (!roomData.exists) {
        return false;
      }

      return roomData.data().password == pwd;
    } catch (err) {
      return false;
    }
  }
}
