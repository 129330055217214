import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Board } from 'src/app/interfaces/board.interface';
import { RoomService } from 'src/app/services/room/room.service';
import { PlayerService } from 'src/app/services/player/player.service';
import { Player } from 'src/app/interfaces/player.interface';
import { Subscription } from 'rxjs';
import { GameService } from 'src/app/services/game.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit, OnDestroy {

  @Input() board: Board;

  currentPlayer: Player;
  playerSub: Subscription;

  constructor(
    private roomService: RoomService,
    private playerService: PlayerService,
    private gameService: GameService
  ) { }

  ngOnInit(): void {
    this.playerSub = this.playerService.currentPlayer.subscribe((updatedCurrentPlayer) => {
      this.currentPlayer = updatedCurrentPlayer;
    });
  }

  async revealCard(cardInd: number) {
    if (!this.board.cards[cardInd].isRevealed) {
      this.gameService.revealCard(cardInd);
    }
  }

  ngOnDestroy() {
    if (this.playerSub) {
      this.playerSub.unsubscribe();
    }
  }
}
