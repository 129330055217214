// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyC7c_pGymFbNh3KS-9oEYqr0IjDFHTYi74",
    authDomain: "spyandseek-459bc.firebaseapp.com",
    databaseURL: "https://spyandseek-459bc.firebaseio.com",
    projectId: "spyandseek-459bc",
    storageBucket: "spyandseek-459bc.appspot.com",
    messagingSenderId: "161980867273",
    appId: "1:161980867273:web:6743a75dc1e1c6983d6318",
    measurementId: "G-4T9EFS04RC"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
