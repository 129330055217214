<div class="team" *ngIf="currentPlayer">
  <mat-chip-list class="mat-chip-list-stacked" aria-label="Team Players">
    <mat-chip title="{{player.name | titlecase}}" *ngFor="let player of team.players; let playerInd = index" 
      (click)="makeSpyMaster(playerInd)" 
      [ngClass]="{
        'accent': color == 'accent',
        'primary': color == 'primary',
        'spy': player.isSpymaster,
        'currentPlayer': player.id == currentPlayer.id
      }"
    >
      {{player.name | firstName | titlecase | maxChars:15}}
      <div style="width:100%"></div>
      
      <mat-icon *ngIf="!player.isSpymaster && team.players.length > 1" matChipRemove (click)="remove(playerInd)" matTooltip="Remove {{player.name | titlecase}}" matTooltipPosition="after">cancel</mat-icon>
      <i *ngIf="player.isSpymaster" class="fa fa-user-secret"></i>
    </mat-chip>
  </mat-chip-list>
</div>