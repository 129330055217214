import { Component, OnInit } from '@angular/core';
import { BoardService } from 'src/app/services/board/board.service';
import { RoomService } from 'src/app/services/room/room.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { GameService } from 'src/app/services/game.service';
import { first } from 'rxjs/internal/operators/first';
import { Room } from 'src/app/interfaces/room.interface';
import { TimerAction } from 'src/app/interfaces/timerEvent.interface';
import { PlayerService } from 'src/app/services/player/player.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  shareUrl: string;

  constructor(
    private boardService: BoardService,
    private roomService: RoomService,
    private snackBarService: MatSnackBar,
    private gameService: GameService,
    private playerService: PlayerService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.shareUrl = this.buildShareLink();
  }

  async newGame() {
    try {
      const newBoard = await this.boardService.new();
      const room: Room = await this.roomService.room$.pipe(first()).toPromise();
      
      await this.roomService.updateWithNewGame(newBoard);
      
      this.gameService.timeEvent.next({
        teamInPlay: room.teamInPlay,
        action: TimerAction.Restart
      });
    } catch (error) {
      console.error(`Error creating new a board`, error);
      this.snackBarService.open(`Error creating new board`, 'Dismiss', { duration: 3000 });
    }
  }

  copyShareLink() {
    this.clipboard.copy(this.shareUrl);
    this.snackBarService.open(`Copied share link!`, 'Dismiss', { duration: 3000 });
  }

  async switchTeams() {
    let room = await this.roomService.room$.pipe(first()).toPromise();
    room = this.playerService.switchTeams(room);
    
    try {
      await this.roomService.updateTeams(room.teams);
    } catch (error) {
      console.error(`Error switching team`, error);
      this.snackBarService.open(`Error switching teams, try again in a few.`, `Dismiss`, { duration: 3000 });
    }
  }

  private buildShareLink(): string {
    const url = window.location.href;
    const lastSlashAt = url.lastIndexOf('/');
    return url.slice(0, lastSlashAt);
  }
}
