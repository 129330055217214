import { Player } from "./player.interface";

export interface Team {
  id?: string,
  score: number,
  color: Color
  players?: Player[],
}

export enum Color {
  Blue,
  Green
}