import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Team } from 'src/app/interfaces/team.interface';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { GameService } from 'src/app/services/game.service';
import { Subscription } from 'rxjs';
import { TimerAction } from 'src/app/interfaces/timerEvent.interface';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements AfterViewInit, OnDestroy {

  @Input() team: Team;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  timerEventSub: Subscription;

  constructor(
    private gameService: GameService
  ) { }

  ngAfterViewInit() {
    this.timerEventSub = this.gameService.timeEvent.subscribe((nextTimerEvent) => {
      switch(nextTimerEvent.action) {
        case TimerAction.Start:
          this.countdown.begin();
          break;
        case TimerAction.Stop:
          this.countdown.stop();
          break;
        case TimerAction.Restart:
          this.countdown.restart();
          break;
        case TimerAction.Pause:
          this.countdown.pause();
          break;
        default:
          this.countdown.stop();
          break;
      }
    });
  }

  async handleCountdownEvent(event: CountdownEvent) {
    switch(event.action) {
      case 'done':
        await this.gameService.switchTeamInPlay();
    }
  }

  ngOnDestroy(): void {
    this.timerEventSub.unsubscribe();
  }
}
