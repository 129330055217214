import { Injectable } from '@angular/core';
import { Board } from 'src/app/interfaces/board.interface';
import { AngularFirestore } from '@angular/fire/firestore';

import { first } from 'rxjs/operators';
import * as uuid from 'uuid';
import { Card, CardType } from 'src/app/interfaces/card.interface';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class BoardService {
  private gameData: GameData;

  constructor(
    private afs: AngularFirestore,
    private utilityService: UtilityService
  ) {}

  async new(): Promise<Board> {
    const boardSize: number = 25;
    const cards: Card[] = [];
    
    if (!this.gameData) {
      const gameDataDocData = await this.afs.doc<GameData>('gameData/original').get().pipe(first()).toPromise();
      this.gameData = gameDataDocData.data() as GameData;
    }
    
    const gameData: GameData = Object.assign({}, this.gameData);

    gameData.deck = this.utilityService.shuffleArray(gameData.deck).slice(0, boardSize);

    const deckSetup = {
      blue: 8,
      green: 9,
      bomb: 1
    };
    
    deckSetup.blue = this.getRandomInt(2) > 0 ? 8 : 9;
    deckSetup.green = 17 - deckSetup.blue;
    
    for(let i=0; i < deckSetup.blue; i++) {
      cards.push({
        type: CardType.Blue,
        word: gameData.deck.pop(),
        isRevealed: false
      });
    }

    for(let i=0; i < deckSetup.green; i++) {
      cards.push({
        type: CardType.Green,
        word: gameData.deck.pop(),
        isRevealed: false
      });
    }

    for(let i=0; i < deckSetup.bomb; i++) {
      cards.push({
        type: CardType.Bomb,
        word: gameData.deck.pop(),
        isRevealed: false
      });
    }

    const numberOfRemainingCards = gameData.deck.length;

    for(let i=0; i < numberOfRemainingCards; i++) {
      cards.push({
        type: CardType.Blank,
        word: gameData.deck.pop(),
        isRevealed: false
      });
    }
    const now = new Date();

    return {
      id: uuid.v4(),
      cards: this.utilityService.shuffleArray(cards),
      createdOn: now.toISOString()
    };
  }

  private getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}

interface GameData {
  deck: string[];
}
