import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing/landing-page/landing-page.component';
import { RoomComponent } from './game/components/room/room.component';
import { JoinroomGuardService as JoinroomGuard } from './guards/joinroom-guard.service';
import { JoinRoomResolverService as JoinRoomResolver } from './resolvers/join-room-resolver.service';

const routes: Routes = [
  {
    path: 'room/:roomId/:password/:playerName',
    component: RoomComponent,
    canActivate: [JoinroomGuard],
    resolve: { roomData: JoinRoomResolver }
  },
  {
    path: 'room/:roomId/:password',
    component: LandingPageComponent
  },
  {
    path: 'new',
    component: LandingPageComponent
  },
  {
    path: '**',
    component: LandingPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
