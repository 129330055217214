<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="container light-theme" *ngIf="!isLoading">
  <div style="flex:1"></div>
  <div class="form-container">
    <div class="mat-headline" style="text-align: center;">Play Codewords</div>
    <mat-card>
      <mat-card-content>
        <form class="setup-form">
          <mat-form-field class="setup-form-full-width">
            <mat-label>Your name</mat-label>
            <input matInput placeholder="Clover"
              value=""
              name="name"
              [(ngModel)]="name"
            >
          </mat-form-field>
  
          <mat-form-field class="setup-form-full-width">
            <mat-label>Room</mat-label>
            <input matInput placeholder="silly-pandas"
              [(ngModel)]="room"
              name="room"
            >
          </mat-form-field>
  
          <mat-form-field class="setup-form-full-width">
            <mat-label>Password</mat-label>
            <input matInput
              [(ngModel)]="password"
              name="password"
            >
          </mat-form-field>
        </form>
        
      </mat-card-content>
      <mat-card-actions class="actions">
        <button mat-button color="primary" (click)="join()">JOIN ROOM</button>
        <button mat-button color="accent" (click)="createRoom()">CREATE ROOM</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div style="flex:1"></div>
</div>