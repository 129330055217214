import { Color } from './team.interface';

export interface Card {
  type: CardType,
  word: string,
  isRevealed: boolean
}

export enum CardType {
  Blue,
  Green,
  Bomb,
  Blank
}