export interface TimerEvent {
  action: TimerAction,
  teamInPlay?: string
}

export enum TimerAction {
  Start,
  Stop,
  Restart,
  Pause
}