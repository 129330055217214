import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TimerEvent, TimerAction } from '../interfaces/timerEvent.interface';
import { RoomService } from './room/room.service';
import { Room } from '../interfaces/room.interface';
import { CardType, Card } from '../interfaces/card.interface';
import { Team, Color } from '../interfaces/team.interface';
import { PlayerService } from './player/player.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as isEqual from 'lodash.isEqual';
import { UtilityService } from './utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  public timeEvent: BehaviorSubject<TimerEvent>;
  public room: Room;

  constructor(
    private roomService: RoomService,
    private playerService: PlayerService,
    private utilityService: UtilityService,
    private snackBarService: MatSnackBar
  ) {
    this.timeEvent = new BehaviorSubject({
      action: TimerAction.Start,
      teamInPlay: ''
    });
    
    this.roomService.room$.subscribe((roomUpdate: Room) => {
      this.timeEvent.next({
        action:  TimerAction.Start,
        teamInPlay: roomUpdate.teamInPlay
      });
      
      this.restartTimerOnPlayerJoining(this.room, roomUpdate);
      this.restartTimerOnNewBoard(this.room, roomUpdate);
      this.handleGameOver(this.room, roomUpdate);

      this.room = roomUpdate;
    });

  }

  async revealCard(cardInd: number) {
    try {
      if (!this.canCurrentPlayerRevealCard()) {
        this.snackBarService.open('It\'s not your turn yet.  Wait for your team\'s turn.', 'Dismiss', { duration: 3000 });
        return;
      }
      
      const updatePromises: Promise<void>[] = [];

      const card = this.room.board.cards[cardInd];
      card.isRevealed = true;

      if (this.room.board.cards[cardInd].type == CardType.Bomb) {
        const teamInPlayInd = this.getTimeInPlayIndex();
        const winningTeamInd = this.utilityService.getNextTeamIndex(this.room.teams, teamInPlayInd);
        this.gameOver(this.room.teams[winningTeamInd]);
      } else if (!this.validCardForTeamInPlay(card)) {
        console.log('switching team invalid play');
        
        updatePromises.push(this.switchTeamInPlay());
      }

      updatePromises.push(this.roomService.updateBoard(this.room.board));
      await Promise.all(updatePromises);

    } catch (error) {
      console.error(`Error revealing card, ${cardInd}`);
      throw error;
    }
  }

  async switchTeamInPlay(): Promise<void> {
    try {
      const teamInPlayInd = this.getTimeInPlayIndex();
      const nextTeamInd = this.utilityService.getNextTeamIndex(this.room.teams, teamInPlayInd);
  
      this.room.teamInPlay = this.room.teams[nextTeamInd].id;
      await this.roomService.updateTeamInPlay(this.room.teamInPlay);
    } catch (error) {
      console.error(`Error switching team in play`, error);
      throw error;
    }
  }

  private async restartTimerOnPlayerJoining(currentRoom: Room, nextRoom: Room) {
    if (!currentRoom || !nextRoom) return;
    
    const totalExistingPlayers = currentRoom.teams.reduce((total, team) => {
      total += team.players.length;
      return total;
    }, 0);

    const totalNewPlayers = nextRoom.teams.reduce((total, team) => {
      total += team.players.length;
      return total;
    }, 0);

    if (totalNewPlayers > totalExistingPlayers) {
      this.timeEvent.next({
        action: TimerAction.Restart,
        teamInPlay: nextRoom.teamInPlay
      });
    }
  }

  private async restartTimerOnNewBoard(currentRoom: Room, nextRoom: Room) {
    if (!currentRoom || !nextRoom) return;
    const prevBoard = currentRoom.board.cards.map(card => card.type + card.word);
    const nextBoard = nextRoom.board.cards.map(card => card.type + card.word);

    if (!isEqual(prevBoard, nextBoard)) {
      this.timeEvent.next({
        action: TimerAction.Restart,
        teamInPlay: nextRoom.teamInPlay
      });

      this.snackBarService.open(`New game started`, 'Dismiss', { duration: 3000 });
    }
  }

  private async handleGameOver(currentRoom: Room, nextRoom: Room) {
    const countedCards = this.utilityService.countCards(nextRoom.board);
    if (countedCards.blueCount == countedCards.blueRevealedCount) {
      this.gameOver(nextRoom.teams[0]);
    } else if (countedCards.greenCount == countedCards.greenRevealedCount) {
      this.gameOver(nextRoom.teams[1]);
    }
  }

  private async gameOver(winningTeam: Team) {
    this.snackBarService.open(`Team ${Color[winningTeam.color]} wins!`, `Dismiss`, { duration: 3000 });

    await this.roomService.roomDocRef.update({
      isGameOver: true
    });
  }

  private canCurrentPlayerRevealCard(): boolean {
    const currentPlayer = this.playerService.currentPlayer.getValue();
    const teamInPlay = this.getTeamInPlay();

    return teamInPlay.players.map(player => player.id).includes(currentPlayer.id);
  }

  private validCardForTeamInPlay(card: Card): boolean {
    const teamInPlay: Team = this.getTeamInPlay();
    switch(card.type) {
      case CardType.Blue:
        return teamInPlay.color == Color.Blue;
      case CardType.Green:
        return teamInPlay.color == Color.Green;
      default:
        return false;
    }
  }

  private getTeamInPlay(): Team {
    return this.room.teams.find((team) => team.id == this.room.teamInPlay);
  }

  private getTimeInPlayIndex(): number {
    return this.room.teams.findIndex((team) => team.id == this.room.teamInPlay);
  }
}