import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Team, Color } from 'src/app/interfaces/team.interface';
import { NewSpyMasterEvent } from '../team-bar/team-bar.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PlayerService } from 'src/app/services/player/player.service';
import { Player } from 'src/app/interfaces/player.interface';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent implements OnInit, OnDestroy {
  
  currentPlayer: Player;
  subs: Subscription[] = [];

  @Input() team: Team;

  @Output() newSpyMasterEvent = new EventEmitter<NewSpyMasterEvent>();
  @Output() removePlayer = new EventEmitter<{playerInd: number, teamId: string}>();

  public isInPlay: boolean;
  public color: string;
  private newSpyMasterEventSubject: Subject<NewSpyMasterEvent> = new Subject<NewSpyMasterEvent>();

  constructor(private playerService: PlayerService) {}

  ngOnInit(): void {
    this.newSpyMasterEventSubject.pipe(debounceTime(500)).subscribe((newSpyMasterEvent: NewSpyMasterEvent) => {
      this.newSpyMasterEvent.emit(newSpyMasterEvent);
    });

    this.color = this.team.color == Color.Blue ? 'primary' : 'accent';

    this.subs.push(this.playerService.currentPlayer.subscribe(currentPlayer => {
      this.currentPlayer = currentPlayer;
    }));
  }

  makeSpyMaster(playerInd) {
    // check if player is already spy
    const currentSpy = this.team.players.findIndex((player) => player.isSpymaster);

    if (currentSpy !== playerInd) {
      this.newSpyMasterEventSubject.next({
        player: this.team.players[playerInd],
        team: this.team
      });
    }
  }

  remove(playerInd) {
    this.removePlayer.emit({
      playerInd,
      teamId: this.team.id
    });
  }

  ngOnDestroy(): void {
    if (this.subs.length) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
    this.newSpyMasterEventSubject.unsubscribe();
  }
}
