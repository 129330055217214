import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AppMaterialModule } from '../app-material/app-material.module';

@NgModule({
  declarations: [LandingPageComponent],
  imports: [
    CommonModule,
    AppMaterialModule,
    
  ],
  exports: [
    LandingPageComponent
  ]
})
export class LandingModule { }
