import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'maxChars'})
export class MaxCharsPipe implements PipeTransform {
  transform(value: string, maxChars: number = 10): string {
    if (value.length <= maxChars) {
      return value;
    }

    return value.slice(0, maxChars - 1) + '...';
  }
}