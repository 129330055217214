import { Injectable } from '@angular/core';
import { Player } from 'src/app/interfaces/player.interface';
import * as uuid from 'uuid';
import { BehaviorSubject } from 'rxjs';
import { Room } from 'src/app/interfaces/room.interface';
import { Team } from 'src/app/interfaces/team.interface';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  public currentPlayer: BehaviorSubject<Player> = new BehaviorSubject<Player>(null);

  constructor(
  ) { }

  new(name: string, isSpymaster: boolean = false) {
    return {
      id: uuid.v4(),
      name,
      isSpymaster
    };
  }

  syncCurrentPlayerWithTeam(team: Team) {
    const currentPlayer = this.currentPlayer.getValue();

    const foundInd = team.players.findIndex((player) => player.id == currentPlayer.id);

    if (foundInd >= 0) {
      this.currentPlayer.next(team.players[foundInd]);
    }
  }

  syncWithRoom(room: Room) {
    room.teams.forEach((team) => {
      this.syncCurrentPlayerWithTeam(team);
    });
  }

  switchTeams(room: Room): Room {
    try {
      let playerInd = room.teams[0].players.findIndex((player) => player.id == this.currentPlayer.value.id);
      let foundOnTeam = 0;
  
      if (playerInd < 0) {
        playerInd = room.teams[1].players.findIndex((player) => player.id == this.currentPlayer.value.id);
        foundOnTeam = 1;
      }

      console.log(`foundOnTeam ${foundOnTeam} switching to team ${1 - foundOnTeam}`);
      
  
      room.teams[foundOnTeam].players.splice(playerInd, 1);
      room.teams[1 - foundOnTeam].players.push(this.currentPlayer.value);
      console.log(`room.teams=`, room.teams);
      
      return room;
    } catch (error) {
      console.error(`Error switching teams`, error);
      throw error;
    }
  }
}
