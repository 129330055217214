<div class="container">
  <mat-grid-list cols="5" gutterSize="20px">
    <mat-grid-tile *ngFor="let card of board.cards; let cardInd = index">
      <div *ngIf="currentPlayer.isSpymaster; then revealAllCards else hideCards"></div>
      <ng-template #revealAllCards>
        <mat-card class="card"
          matRipple
          (click)="revealCard(cardInd)"
          [ngClass]="{
            'lightBlue': card.type == 0 && !card.isRevealed,
            'darkBlue': card.type == 0 && card.isRevealed,
            'lightGreen': card.type == 1 && !card.isRevealed,
            'darkGreen': card.type == 1 && card.isRevealed,
            'lightRed': card.type == 2 && !card.isRevealed,
            'darkRed': card.type == 2 && card.isRevealed,
            'darkGrey': card.type == 3 && !card.isRevealed,
            'lightGrey': card.type == 3 && card.isRevealed
          }" 
        >
          <mat-card-content>
            <div class="flex"></div>
            <div class="flex">{{card.word}}</div>
            <div class="flex"></div>
          </mat-card-content>
        </mat-card>
      </ng-template>
      <ng-template #hideCards>
        <mat-card class="card"
          matRipple
          (click)="revealCard(cardInd)"
          [ngClass]="{
            'darkBlue': card.type == 0 && card.isRevealed,
            'darkGreen': card.type == 1 && card.isRevealed,
            'darkRed': card.type == 2 && card.isRevealed,
            'lightGrey': card.type == 3 && card.isRevealed
          }" 
        >
          <mat-card-content>
            <div class="flex"></div>
            <div class="flex">{{card.word}}</div>
            <div class="flex"></div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </mat-grid-tile>
  </mat-grid-list>
</div>