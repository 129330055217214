import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { RoomService } from '../services/room/room.service';

@Injectable({
  providedIn: 'root'
})
export class JoinRoomResolverService implements Resolve<Promise<boolean>>{

  constructor(
    private roomService: RoomService
  ) { }

  resolve(router: ActivatedRouteSnapshot): Promise<boolean> {
    const roomId = router.params['roomId'];
    const pwd = router.params['password'];
    const playerName = router.params['playerName'];

    try {
      
      return this.roomService.join(roomId, pwd, playerName);
    } catch (error) {
      console.error(`error resolving data for room route, join failed`, error);
      throw error;
    }
  }
}
