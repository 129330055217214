<mat-progress-bar *ngIf="!room" mode="indeterminate"></mat-progress-bar>
<div class="container" *ngIf="room">
  <div class="sidenav">
    <app-team-bar [room]="room" [team]="room.teams[0]"></app-team-bar>
    
  </div>
  <div class="main">
    <app-scoreboard [room]="room"></app-scoreboard>
    <app-board [board]="room.board"></app-board>
    <app-controls></app-controls>
  </div>

  <div class="sidenav">
    <app-team-bar [room]="room" [team]="room.teams[1]"></app-team-bar>
  </div>
</div>