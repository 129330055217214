import { Component, OnInit } from '@angular/core';
import { RoomService } from 'src/app/services/room/room.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  isLoading:boolean = false;
  name: string;
  room: string;
  password: string;

  constructor(
    private roomService: RoomService,
    private utilityService: UtilityService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.room = this.activatedRoute.snapshot.params['roomId'];
    this.password = this.activatedRoute.snapshot.params['password'];
    console.log('room:', this.room);
    console.log('pwd:', this.password);
    
  }

  async createRoom() {
    this.isLoading = true;
    const setupComplete = await this.roomService.create(
      this.room,
      this.password,
      this.name
    );
    this.isLoading = false;

    if (setupComplete) {
      this.router.navigate([`/room`, this.room, this.password, this.name]);
    }
  }

  async join() {
    this.router.navigate(['/room', this.room, this.password, this.name]);
  }

  async loadData() {
    await this.utilityService.addCodenames();
  }
}
