import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Room } from 'src/app/interfaces/room.interface';
import { CardType } from 'src/app/interfaces/card.interface';

@Component({
  selector: 'app-scoreboard',
  templateUrl: './scoreboard.component.html',
  styleUrls: ['./scoreboard.component.scss']
})
export class ScoreboardComponent implements OnInit, OnChanges {

  @Input() room: Room;
  
  teamScores:  TeamScore[] = [];

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.calculateScore();
  }

  calculateScore() {
    const organizedDeck = this.room.board.cards.reduce((deck, card) => {
      if (card.type == CardType.Blue || card.type == CardType.Green) {
        const cardType: string = CardType[card.type];
        deck[cardType].push(card);

        if (card.isRevealed) {
          const revealedKey = cardType + 'Revealed';
          deck[revealedKey].push(card);
        }
      }
      return deck;
    }, {
      'Blue': [],
      'Green': [],
      'BlueRevealed': [],
      'GreenRevealed': []
    });
    
    this.teamScores[0] = {
      color: 'primary',
      totalPossibleScore: organizedDeck['Blue'].length,
      score: organizedDeck['BlueRevealed'].length
    };

    this.teamScores[1] = {
      color: 'accent',
      totalPossibleScore: organizedDeck['Green'].length,
      score: organizedDeck['GreenRevealed'].length
    };
  }
}

interface TeamScore {
  color: string,
  totalPossibleScore: number,
  score: number
}