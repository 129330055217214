import { Injectable } from '@angular/core';
import { Room } from '../../interfaces/room.interface';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Color, Team } from '../../interfaces/team.interface';
import { BoardService } from '../board/board.service';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Player } from '../../interfaces/player.interface';
import { Board } from 'src/app/interfaces/board.interface';
import { PlayerService } from '../player/player.service';
import { CardType } from 'src/app/interfaces/card.interface';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  public roomDocRef: DocumentReference;
  public room$: Observable<Room>;

  constructor(
    private afs: AngularFirestore,
    private toastr: ToastrService,
    private boardService: BoardService,
    private playerService: PlayerService,
    private utilityService: UtilityService
  ) { }

  async create(roomName: string, password: string, playerName: string): Promise<boolean> {
    try {
      const roomDoc = this.afs.doc<Room>(`rooms/${roomName}`);
      const teamOneId = uuid.v4();
      const teamTwoId = uuid.v4();
      const board = await this.boardService.new();

      let room: Room = {
        id: roomName,
        password,
        board,
        teams: [{
          id: teamOneId,
          color: Color.Blue,
          score: 0,
          players: []
        }, {
          id: teamTwoId,
          color: Color.Green,
          score: 0,
          players: []
        }],
        teamInPlay: teamOneId,
        isGameOver: false
      };

      room = this.addPlayer(room, playerName);

      this.roomDocRef = roomDoc.ref;
      this.room$ = roomDoc.valueChanges();

      await roomDoc.set(room);
      return true;
    } catch (error) {
      this.toastr.error(`Unable to create ${roomName || ''}`, `Try again in a few`);
      console.error(`Error setting up new room named ${roomName}`, error);
      return false;
    }
  }

  async join(roomId: string, password: string, playerName: string): Promise<boolean> {
    try {
      const doc = this.afs.doc<Room>(`rooms/${roomId}`);
      this.roomDocRef = doc.ref;
      this.room$ = doc.valueChanges();
      
      let currentRoom: Room = await this.room$.pipe(first()).toPromise();
      
      if (currentRoom.password !== password) {
        throw new Error('Invalid password');
      }

      currentRoom = this.addPlayer(currentRoom, playerName);
      await this.updateRoom(currentRoom);

      return true;
    } catch (err) {
      this.toastr.error(`Unable to join ${roomId || ''}`, `Check your password?`);
      return false;
    }
  }

  addPlayer(room: Room, playerName: string): Room {
    if (!room.teams.length) {
      const err = 'No teams in room? Weird.';
      console.error(err);
      throw new Error(err);
    }

    let smallestTeamSize = room.teams[0].players.length;
    let smallestTeamInd = 0;
    let playerExists = false;

    for (let i=0; i<room.teams.length; i++) {
      const team = room.teams[i];

      if (team.players.length < smallestTeamSize) {
        smallestTeamInd = i;
        smallestTeamSize = team.players.length;
      }

      playerExists = playerExists || team.players
        .map((player) => player.name.toLowerCase())
        .includes(playerName.toLowerCase());
    }

    if (playerExists) {
      let teamInd, playerInd = 0;
      for(let i=0; i < room.teams.length; i++) {
        const found = room.teams[i].players
          .map((player) => player.name.toLowerCase())
          .findIndex((name) => name == playerName.toLowerCase());

        if (found >= 0) {
          teamInd = i;
          playerInd = found;
        }
      }

      this.playerService.currentPlayer.next(room.teams[teamInd].players[playerInd]);
    } else {
      const newPlayer = this.playerService.new(playerName);
      this.playerService.currentPlayer.next(newPlayer);
      room.teams[smallestTeamInd].players.push(newPlayer);
    }

    return room;
  }

  getSpyMaster(team: Team): Player {
    return team.players.find((player) => player.isSpymaster);
  }

  getInPlayTeam(room: Room): Team {
    return room.teams.find((team) => team.id == room.teamInPlay);
  }



  async updateRoom(room: Room) {
    try {
      await this.roomDocRef.update(room);
    } catch (error) {
      console.error('Error updating room', room, error);
      throw error;
    }
  }

  async updateTeam(newTeam: Team) {
    try {
      const room = await this.room$.pipe(first()).toPromise();
      const teamInd = room.teams.findIndex((team) => team.id === newTeam.id);
      room.teams[teamInd] = newTeam;

      this.playerService.syncCurrentPlayerWithTeam(newTeam);

      await this.roomDocRef.update({
        "teams": room.teams
      });
    } catch (error) {
      console.error(`Error updating team, ${newTeam.id}`);
    }
  }

  async updateTeams(teams: Team[]) {
    try {
      await this.roomDocRef.update({
        "teams": teams
      });
    } catch (error) {
      console.error(`Error updating both teams`, error);
    }
  }
  
  async updateBoard(board: Board) {
    try {
      await this.roomDocRef.update({
        board: board
      });
    } catch (error) {
      console.error(`Error updating board, ${board.id}`, error);
      throw error;
    }
  }

  async updateWithNewGame(board: Board) {
    const cardCounts = this.utilityService.countCards(board);

    const room = await this.room$.pipe(first()).toPromise();
    if (cardCounts.blueCount < cardCounts.greenCount) {
      room.teamInPlay = room.teams[0].id;
    } else {
      room.teamInPlay = room.teams[1].id;
    }

    try {
      await this.roomDocRef.update({
        board: board,
        teamInPlay: room.teamInPlay,
        isGameOver: false
      });
    } catch (error) {
      console.error(`Error updating board, ${board.id}`, error);
      throw error;
    }
  }
  

  async updateTeamInPlay(teamId: string) {
    try {
      await this.roomDocRef.update({
        teamInPlay: teamId
      });
    } catch (error) {
      console.error(`Error updating team in play to teamId:${teamId}`, error);
      throw error;
    }
  }
}
