import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Room } from 'src/app/interfaces/room.interface';
import { Team } from 'src/app/interfaces/team.interface';
import { Player } from 'src/app/interfaces/player.interface';
import { RoomService } from 'src/app/services/room/room.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GameService } from 'src/app/services/game.service';
import { Subscribable, Subscription } from 'rxjs';

@Component({
  selector: 'app-team-bar',
  templateUrl: './team-bar.component.html',
  styleUrls: ['./team-bar.component.scss']
})
export class TeamBarComponent{

  isGameOverSub: Subscription;

  @Input() room: Room;
  @Input() team: Team;

  constructor(
    private roomService: RoomService,
    private snackBarService: MatSnackBar, 
    private gameService: GameService
  ) { }
  
  async setSpyMaster(event: NewSpyMasterEvent) {
    const teamInd = this.room.teams.findIndex((team) => event.team.id === team.id);
    const playerInd = this.room.teams[teamInd].players.findIndex((player) => player.id == event.player.id);
    const nextSpyMaster: Player = this.room.teams[teamInd].players[playerInd];

    try {
      this.room.teams[teamInd].players = this.room.teams[teamInd].players.map((player, i) => {
        return {
          ...player,
          isSpymaster: playerInd === i
        }
      });

      await this.roomService.updateTeam(this.room.teams[teamInd]);
      this.snackBarService.open(
        `${nextSpyMaster.name} is Spymaster`, 'Dismiss', {
          duration: 3000
        }
      );
    } catch (e) {
      console.error('Failed to set spy master', e);
      this.snackBarService.open(`Unable to set spy master`, 'Dismiss', { duration: 5000 });
    }
  }

  async removePlayer(event: {playerInd: number, teamId: string}) {
    try {
      const team = this.room.teams.find((team) => team.id === event.teamId);

      console.log('teams', team.players);
      
      team.players.splice(event.playerInd, 1);
      console.log('playerInd', event.playerInd);
      console.log('teams', team.players);
      
      await this.roomService.updateTeam(team);
    } catch (error) {
      console.error(`Error removing player`, error);
      this.snackBarService.open(`Error removing player`, `Dismiss`, { duration: 3000 });
    }

  }

  async endTurn() {
    await this.gameService.switchTeamInPlay();
  }
}

export interface NewSpyMasterEvent {
  player: Player,
  team: Team
}