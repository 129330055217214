<div class="teams">
  <div class="title">
    <span class="mat-title">Team {{team.color | printColor }}</span>
  </div>

  <app-team-list 
    [team]="team"
    (newSpyMasterEvent)="setSpyMaster($event)"
    (removePlayer)="removePlayer($event)"
  ></app-team-list>

  <div class="flex"></div>

  <div class="turnControls" *ngIf="!room.isGameOver">
    <app-timer [team]="team" *ngIf="room.teamInPlay == team.id"></app-timer>
    <button 
      *ngIf="room.teamInPlay  == team.id"
      mat-button color="warn"
      (click)="endTurn()"
    >END TURN</button>
  </div>

</div>