<div class="container">
  <button mat-icon-button (click)="switchTeams()" aria-label="Switch Teams" matTooltip="Switch Teams" matTooltipPosition="above">
    <mat-icon>swap_horiz</mat-icon>
  </button>
  <button mat-icon-button (click)="newGame()" aria-label="New Game" matTooltip="Start new game" matTooltipPosition="above">
    <mat-icon>repeat</mat-icon>
  </button>
  <button mat-icon-button (click)="copyShareLink()" aria-label="Share link" matTooltip="Copy share link" matTooltipPosition="above">
    <mat-icon>share</mat-icon>
  </button>
</div>